import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestros servicios</h2>
          <p>
          Servicios de Elevadores: Desde la fabricación e instalación de una amplia gama de modelos, hasta el mantenimiento especializado y la aplicación de tecnología avanzada para soluciones eficientes y seguras adaptadas a cada cliente.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4 ">
                <div className="service-icon">
                  <i className={`${d.icon} fa-sm`}></i>
                </div>
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

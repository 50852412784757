import React from "react";
import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Galería</h2>
          <p>Descubre nuestra excelencia en elevadores: seguridad y calidad garantizadas en cada proyecto!</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data && props.data.redilas ? (
              props.data.redilas.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-sm-6 col-md-4 col-lg-4"
                >
                  <Image
                    title={d.title}
                    largeImage={d.largeImage}
                    smallImage={d.smallImage}
                  />
                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
        
      </div>
    </div>
  );
};

